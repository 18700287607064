var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questionDetail"},[_c('div',{staticClass:"stxq_banner"},[_c('h3',[_vm._v(_vm._s(_vm.name))]),_c('h5',[_vm._v("本卷共"+_vm._s(_vm.questionDetail.length)+"道试题")])]),_c('div',{staticClass:"question-main"},[(_vm.questionDetail.length > 0)?_c('div',{staticClass:"question-list"},[_c('div',{staticClass:"st_content_txt"},[_c('div',{staticClass:"st_content_txt_tm"},[_c('span',[_vm._v(_vm._s(_vm.nowIndex + 1)+"、")]),(_vm.questionDetail[_vm.nowIndex].type === 'SINGLE')?_c('em',[_vm._v("[单选]")]):_vm._e(),(_vm.questionDetail[_vm.nowIndex].type === 'MULTIPLE')?_c('em',[_vm._v("[多选]")]):_vm._e(),(_vm.questionDetail[_vm.nowIndex].type === 'READING_COMPREHENSION')?_c('em',[_vm._v("[阅读理解]")]):_vm._e()]),(_vm.questionDetail[_vm.nowIndex].type != 'READING_COMPREHENSION')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.questionDetail[_vm.nowIndex].questionDetail)}}):_vm._e(),(_vm.questionDetail[_vm.nowIndex].type === 'SINGLE')?_c('div',{staticClass:"st_content_txt_xx"},[_c('el-radio-group',{on:{"change":_vm.selectAnswer},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},_vm._l((_vm.questionDetail[_vm.nowIndex]
                .answers.answerItems),function(answerItem,answerIndex){return _c('el-radio',{key:answerIndex,attrs:{"label":answerItem.key}},[_vm._v(_vm._s(answerItem.content))])}),1)],1):_vm._e(),(_vm.questionDetail[_vm.nowIndex].type === 'MULTIPLE')?_c('div',{staticClass:"st_content_txt_xx"},[_c('el-checkbox-group',{on:{"change":_vm.selectMultipleAnswer},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},_vm._l((_vm.questionDetail[_vm.nowIndex]
                .answers.answerItems),function(answerItem,answerIndex){return _c('el-checkbox',{key:answerIndex,attrs:{"label":answerItem.key}},[_vm._v(_vm._s(answerItem.content))])}),1)],1):_vm._e(),(_vm.questionDetail[_vm.nowIndex].type === 'READING_COMPREHENSION')?_c('div',{staticClass:"reading"},[_c('div',{staticClass:"readingContent",domProps:{"innerHTML":_vm._s(_vm.questionDetail[_vm.nowIndex].questionDetail)}}),_vm._l((_vm.questionDetail[_vm.nowIndex]
              .answers.questionItems),function(readingItem,readingIndex){return _c('div',{key:readingIndex,staticClass:"st_content_txt_xx"},[_c('div',{staticClass:"st_content_txt_tm"},[_c('span',[_vm._v(_vm._s(_vm.nowIndex + 1)+"-"+_vm._s(readingIndex + 1)+"、")]),_vm._v(" "+_vm._s(readingItem.question)+" ")]),_c('el-radio-group',{on:{"change":_vm.selectReadingAnswer},model:{value:(readingItem.answers.selectAnswer),callback:function ($$v) {_vm.$set(readingItem.answers, "selectAnswer", $$v)},expression:"readingItem.answers.selectAnswer"}},_vm._l((readingItem.answers
                  .answerItems),function(answerItem,answerIndex){return _c('el-radio',{key:answerIndex,attrs:{"label":answerItem.key}},[_vm._v(_vm._s(answerItem.content))])}),1),(_vm.showAnswerAnalysis)?_c('div',{staticClass:"answerAnalysis"},[_c('div',{staticClass:"analysisTitle"},[_vm._v("答案解析：")]),_c('div',{staticClass:"analysisContent",domProps:{"innerHTML":_vm._s(readingItem.answers.answerAnalysis)}})]):_vm._e()],1)})],2):_vm._e()]),(
          _vm.showAnswerAnalysis &&
          _vm.questionDetail[_vm.nowIndex].type != 'READING_COMPREHENSION'
        )?_c('div',{staticClass:"answerAnalysis"},[_c('div',{staticClass:"analysisTitle"},[_vm._v("答案解析：")]),_c('div',{staticClass:"analysisContent",domProps:{"innerHTML":_vm._s(_vm.questionDetail[_vm.nowIndex].answers.answerAnalysis)}})]):_vm._e(),_c('div',{staticClass:"btns"},[(_vm.nowIndex != 0)?_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.clickBefore}},[_vm._v("上一题")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.clickOk}},[_vm._v("确定")])],1)]):_vm._e(),_c('div',{staticClass:"main-right"},_vm._l((_vm.questionDetail),function(item,index){return _c('div',{key:index,staticClass:"index-view-item",class:{ 'index-view-item-bg': index === _vm.nowIndex },on:{"click":function($event){$event.stopPropagation();return _vm.clickChoseIndex(index)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }