<template>
  <div class="questionDetail">
    <div class="stxq_banner">
      <h3>{{ name }}</h3>
      <h5>本卷共{{ questionDetail.length }}道试题</h5>
    </div>
    <div class="question-main">
      <div class="question-list" v-if="questionDetail.length > 0">
        <div class="st_content_txt">
          <div class="st_content_txt_tm">
            <span>{{ nowIndex + 1 }}、</span>
            <em v-if="questionDetail[nowIndex].type === 'SINGLE'">[单选]</em>
            <em v-if="questionDetail[nowIndex].type === 'MULTIPLE'">[多选]</em>
            <em v-if="questionDetail[nowIndex].type === 'READING_COMPREHENSION'"
              >[阅读理解]</em
            >
            <!-- {{ questionDetail[nowIndex].question }} -->
          </div>
          <div
            v-if="questionDetail[nowIndex].type != 'READING_COMPREHENSION'"
            v-html="questionDetail[nowIndex].questionDetail"
          ></div>
          <!-- 单选 -->
          <div
            v-if="questionDetail[nowIndex].type === 'SINGLE'"
            class="st_content_txt_xx"
          >
            <el-radio-group v-model="radio" @change="selectAnswer">
              <el-radio
                :label="answerItem.key"
                v-for="(answerItem, answerIndex) in questionDetail[nowIndex]
                  .answers.answerItems"
                :key="answerIndex"
                >{{ answerItem.content }}</el-radio
              >
            </el-radio-group>
          </div>
          <!-- 多选 -->
          <div
            v-if="questionDetail[nowIndex].type === 'MULTIPLE'"
            class="st_content_txt_xx"
          >
            <el-checkbox-group
              v-model="checkList"
              @change="selectMultipleAnswer"
            >
              <el-checkbox
                :label="answerItem.key"
                v-for="(answerItem, answerIndex) in questionDetail[nowIndex]
                  .answers.answerItems"
                :key="answerIndex"
                >{{ answerItem.content }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <!-- 阅读理解 -->
          <div
            v-if="questionDetail[nowIndex].type === 'READING_COMPREHENSION'"
            class="reading"
          >
            <div
              class="readingContent"
              v-html="questionDetail[nowIndex].questionDetail"
            ></div>
            <div
              class="st_content_txt_xx"
              v-for="(readingItem, readingIndex) in questionDetail[nowIndex]
                .answers.questionItems"
              :key="readingIndex"
            >
              <div class="st_content_txt_tm">
                <span>{{ nowIndex + 1 }}-{{ readingIndex + 1 }}、</span>
                {{ readingItem.question }}
              </div>
              <el-radio-group
                v-model="readingItem.answers.selectAnswer"
                @change="selectReadingAnswer"
              >
                <el-radio
                  :label="answerItem.key"
                  v-for="(answerItem, answerIndex) in readingItem.answers
                    .answerItems"
                  :key="answerIndex"
                  >{{ answerItem.content }}</el-radio
                >
              </el-radio-group>
              <div v-if="showAnswerAnalysis" class="answerAnalysis">
                <div class="analysisTitle">答案解析：</div>
                <div
                  class="analysisContent"
                  v-html="readingItem.answers.answerAnalysis"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 单选和多选的答案解析 -->
        <div
          v-if="
            showAnswerAnalysis &&
            questionDetail[nowIndex].type != 'READING_COMPREHENSION'
          "
          class="answerAnalysis"
        >
          <div class="analysisTitle">答案解析：</div>
          <div
            class="analysisContent"
            v-html="questionDetail[nowIndex].answers.answerAnalysis"
          ></div>
        </div>
        <!-- 操作按钮 -->
        <div class="btns">
          <el-button
            v-if="nowIndex != 0"
            type="primary"
            plain
            @click="clickBefore"
            >上一题</el-button
          >
          <el-button type="primary" @click="clickOk">确定</el-button>
        </div>
      </div>
      <div class="main-right">
        <div
          class="index-view-item"
          :class="{ 'index-view-item-bg': index === nowIndex }"
          v-for="(item, index) in questionDetail"
          :key="index"
          @click.stop="clickChoseIndex(index)"
        >
          {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "questionDetail",
  data() {
    return {
      id: "",
      name: "",
      questionDetail: [],
      nowIndex: 0,
      radio: "-1",
      checkList: [],
      showAnswerAnalysis: false,
    };
  },
  created() {
    this.id = this.$router.history.current.query.id;
    this.name = this.$router.history.current.query.name;
    this.getQuestionDetail();
  },
  methods: {
    // 获取题目
    getQuestionDetail() {
      this.axios
        .get("/api/trainee/question/list", {
          params: {
            classificationId: this.id,
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].answers = JSON.parse(res.data[i].answers);
            if (res.data[i].type === "READING_COMPREHENSION") {
              for (
                let j = 0;
                j < res.data[i].answers.questionItems.length;
                j++
              ) {
                res.data[i].answers.questionItems[j].answers.selectAnswer =
                  "-1";
              }
            }
          }
          this.questionDetail = res.data;
          console.log("questionDetail:", this.questionDetail);
        });
    },
    // 选择答案
    selectAnswer(value) {
      console.log(value);
    },
    selectMultipleAnswer(value) {
      console.log(value);
    },
    selectReadingAnswer(value) {
      console.log(value);
    },
    // 上一题
    clickBefore() {
      this.showAnswerAnalysis = false;
      this.nowIndex -= 1;
    },
    // 选择题号
    clickChoseIndex(index) {
      this.nowIndex = index;
    },
    // 确定
    clickOk() {
      if (this.questionDetail[this.nowIndex].type === "READING_COMPREHENSION") {
        let answerTitle = "";
        let answerType = "";
        let indexArr = [];
        for (
          let i = 0;
          i < this.questionDetail[this.nowIndex].answers.questionItems.length;
          i++
        ) {
          if (
            this.questionDetail[this.nowIndex].answers.questionItems[i].answers
              .selectAnswer === "-1"
          ) {
            this.$message({
              type: "warning",
              message: "请选择答案",
            });
            return;
          }
          if (
            this.questionDetail[this.nowIndex].answers.questionItems[i].answers
              .selectAnswer !=
            this.questionDetail[this.nowIndex].answers.questionItems[i].answers
              .answer
          ) {
            indexArr.push(i + 1);
          }
          if (
            i ===
            this.questionDetail[this.nowIndex].answers.questionItems.length - 1
          ) {
            console.log(indexArr);
            if (indexArr.length === 0) {
              answerTitle = "正确";
              answerType = "success";
            } else {
              answerTitle = "第" + indexArr.join(",") + "道题错误";
              answerType = "error";
            }
            this.$confirm(answerTitle, "答题结果", {
              confirmButtonText: "下一题",
              cancelButtonText: "查看解析",
              type: answerType,
            })
              .then(() => {
                if (this.nowIndex === this.questionDetail.length - 1) {
                  this.$message({
                    type: "info",
                    message: "已到最后一题",
                  });
                } else {
                  for (
                    let i = 0;
                    i <
                    this.questionDetail[this.nowIndex].answers.questionItems
                      .length;
                    i++
                  ) {
                    this.questionDetail[this.nowIndex].answers.questionItems[
                      i
                    ].answers.selectAnswer = "-1";
                  }
                  this.showAnswerAnalysis = false;
                  this.nowIndex += 1;
                }
              })
              .catch(() => {
                this.showAnswerAnalysis = true;
              });
          }
        }
      } else if (this.questionDetail[this.nowIndex].type === "MULTIPLE") {
        if (this.checkList.length === 0) {
          this.$message({
            type: "warning",
            message: "请选择答案",
          });
          return;
        }
        let answerTitle = "";
        let answerType = "";
        if (
          this.checkList.length !=
          this.questionDetail[this.nowIndex].answers.answers.length
        ) {
          answerTitle = "错误";
          answerType = "error";
        } else {
          for (let i = 0; i < this.checkList.length; i++) {
            if (
              this.questionDetail[this.nowIndex].answers.answers.indexOf(
                this.checkList[i]
              ) === -1
            ) {
              answerTitle = "错误";
              answerType = "error";
              break;
            }
            if (
              i === this.checkList.length - 1 &&
              this.questionDetail[this.nowIndex].answers.answers.indexOf(
                this.checkList[i]
              ) != -1
            ) {
              answerTitle = "正确";
              answerType = "success";
            }
          }
        }
        this.$confirm(answerTitle, "答题结果", {
          confirmButtonText: "下一题",
          cancelButtonText: "查看解析",
          type: answerType,
        })
          .then(() => {
            if (this.nowIndex === this.questionDetail.length - 1) {
              this.$message({
                type: "info",
                message: "已到最后一题",
              });
            } else {
              this.showAnswerAnalysis = false;
              this.checkList = [];
              this.nowIndex += 1;
            }
          })
          .catch(() => {
            this.showAnswerAnalysis = true;
          });
      } else {
        if (this.radio === "-1") {
          this.$message({
            type: "warning",
            message: "请选择答案",
          });
          return;
        }
        let answerTitle = "";
        let answerType = "";
        if (this.radio === this.questionDetail[this.nowIndex].answers.answer) {
          answerTitle = "正确";
          answerType = "success";
        } else {
          answerTitle = "错误";
          answerType = "error";
        }
        this.$confirm(answerTitle, "答题结果", {
          confirmButtonText: "下一题",
          cancelButtonText: "查看解析",
          type: answerType,
        })
          .then(() => {
            if (this.nowIndex === this.questionDetail.length - 1) {
              this.$message({
                type: "info",
                message: "已到最后一题",
              });
            } else {
              this.showAnswerAnalysis = false;
              this.radio = "-1";
              this.nowIndex += 1;
            }
          })
          .catch(() => {
            this.showAnswerAnalysis = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questionDetail {
  min-height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  .stxq_banner {
    width: 100%;
    height: 149px;
    background: url(../assets/img/question/question-top-bg.png) center top;
    margin-bottom: 20px;
    h3 {
      text-align: center;
      color: #333;
      font-size: 28px;
      padding-top: 20px;
      font-weight: normal;
      font-family: "Arial", "Helvetica Neue", Helvetica, "PingFang SC",
        "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    }
    h5 {
      font-size: 18px;
      color: #666;
      text-align: center;
      line-height: 56px;
      font-weight: normal;
      font-family: "Arial", "Helvetica Neue", Helvetica, "PingFang SC",
        "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    }
  }
  .question-main {
    display: flex;
    width: 1180px;
    .question-list {
      flex: 1;
      background-color: #ffffff;
      padding: 20px;
      .st_content_txt {
        padding: 20px 0 0;
        font-size: 16px;
        color: #333;
        line-height: 24px;
        .st_content_txt_tm {
          font-family: "Arial", "Helvetica Neue", Helvetica, "PingFang SC",
            "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
          em {
            font-style: normal;
            color: #f25051;
          }
        }
        .st_content_txt_xx {
          &::v-deep .el-radio-group,
          .el-checkbox-group {
            display: flex;
            flex-direction: column;
          }
          &::v-deep .el-radio,
          .el-checkbox {
            line-height: 40px;
            height: 40px;
          }
        }
        .reading {
          .readingContent {
            padding: 10px 0 10px 20px;
          }
          .st_content_txt_xx {
            padding-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
      .answerAnalysis {
        font-size: 15px;
        background-color: #f2f2f2;
        padding: 10px;
        .analysisTitle {
          color: #f25051;
          margin-bottom: 10px;
        }
      }
      .btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
      }
    }
    .main-right {
      flex: 0 0 240px;
      background-color: #ffffff;
      margin-left: 1px;
      padding: 20px 10px 20px 20px;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      align-content: flex-start;
      .index-view-item {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        border: 1px solid #1c7ccf;
        color: #1c7ccf;
        text-align: center;
        line-height: 30px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .index-view-item-bg {
        background-color: #1c7ccf;
        color: #ffffff;
      }
    }
  }
}
</style>